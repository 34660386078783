#about {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 4rem;
}
.about-content {
  display: flex;
  width: 90%;
  margin: 0 auto 3rem auto;
}

.about-header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

#about .right {
  width: 40%;
}

#about .left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--darkergreen);
  padding: 1rem;
}

#about .left > p {
  margin: 1rem;
}

#about .nav-about {
  width: 90%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  margin: auto;
}

#about .nav-about a {
  background-color: #fff;
  color: #333;
  padding: 1rem;
  width: 25%;
  text-align: center;
}

#about .about-link {
  display: none;
}

.edu-link {
  text-decoration: underline;
}

#about .contact-links {
  text-align: center;
  width: 100%;
  padding-top: 3rem;
}

#about .contact-links a {
  font-size: 1.2rem;
}

@media screen and (max-width: 1850px) {
  /*  #about {
    display: block;
  } */
  .about-content {
    display: block;
  }
  #about .right {
    width: 0rem;
    display: none;
  }
  #about .left {
    width: 100%;
    padding-bottom: 2rem;
  }
  #about .portrait {
    display: none;
  }
  #about .nav-about {
    padding-top: 0;
    margin-bottom: 2rem;
  }
  #about .nav-about a {
    margin: 1rem;
    height: 3.5rem;
  }
}

@media screen and (max-width: 900px) {
  #about {
    padding: 0;
  }
  .about-content {
    width: 100%;
  }
  #about .nav-about {
    flex-direction: column;
    height: auto;
  }
  #about .nav-about a {
    width: 80%;
  }
  #about .left {
    padding: 1rem;
  }
  #about .contact-links {
    padding-bottom: 3rem;
  }
}
