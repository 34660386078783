.project-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  grid-gap: 3rem;
  margin: 3rem auto;
}

.card {
  position: relative;
  background-color: rgba(51, 51, 51, 0.247);
  padding: 0.8rem 0;
}

.card-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-image {
  width: 16rem;
  height: 14rem;
  margin: auto;
  border: 2px solid #fff;
  box-shadow: 2px 2px 5px #333;
  overflow: hidden;
}

.card-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.links,
.link {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -20%);
}

.link {
  top: 80%;
}

.links a,
.link a {
  background-color: #333;
  border: 2px solid #fff;
  padding: 0.5rem;
  margin: 0.5rem;
  text-align: center;
  width: 8rem;
  font-size: 1.2rem;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.card:hover a:hover,
.card:hover a:hover {
  background-color: rgb(46, 70, 46);
  transform: scale(1.1);
}
.technologies {
  margin: 0.7rem;
  display: flex;
  justify-content: center;
  /* color: #333; */
}
.technologies p {
  background-color: rgba(51, 51, 51, 0.589);
  padding: 0.3rem;
  margin: 0.2rem;
  font-size: 0.7rem;
}

.responsive {
  font-size: 1.1rem;
  color: #333;
  background-color: rgb(241, 136, 136);
  text-align: center;
  width: 70%;
  margin: auto;
  padding: 0.2rem 0;
}

@media screen and (max-width: 1400px) {
  .project-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 950px) {
  .project-list {
    grid-template-columns: 1fr;
  }
  .project-info {
    padding: 1rem;
  }
}
