#home {
  margin: 2rem auto;
}

.home-content {
  max-width: 80rem;
  margin: auto;
  position: relative;
}

.first {
  width: 45%;
  position: absolute;
  top: 2rem;
  left: 0;
  /* animation: homepagefirstgroup 2s forwards; */
}
.second {
  width: 55%;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  animation: homepagesecondgroup 2s forwards 3s;
}
.third {
  width: 45%;
  position: absolute;
  bottom: 2rem;
  left: 0;
  margin-top: 1rem;
  opacity: 0;
  animation: homepagethirdgroup 2s forwards 3s;
}

.portrait {
  width: 100%;
  object-fit: contain;
}

#logo-and-name {
  width: 110%;
  animation: placementbigscreen 4s forwards;
}

.firstletter {
  animation: size 4s forwards;
}

#home .nav-about {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  height: 40%;
  justify-content: space-between;
  padding-left: 1rem;
}

#home a {
  padding: 0.5rem 0;
}

#home .about-link {
  background-color: #fff;
  color: #333;
  width: 70%;
  padding: 1rem;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
#home .contact-links {
  padding-top: 3rem;
  padding-left: 20%;
  text-align: start;
}

.initials {
  position: absolute;
  fill: none;
  stroke: #fafafa;
  stroke-width: 0.5px;
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  opacity: 0;
  animation: logobigscreen 4s forwards;
}

.firstname,
.lastname {
  opacity: 0;
  animation: fullnameanimation 2s forwards 3s;
}

@media screen and (max-width: 1200px) {
  .home-content {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  #logo-and-name {
    width: 100%;
  }

  .first {
    width: 100%;
  }
  .second {
    margin: 0;
    margin-top: 50%;
  }

  .portrait {
    padding-top: 0;
  }

  .third {
    width: 100%;
    position: relative;
  }
  .nav-about {
    padding: 0;
    align-items: center;
    padding-top: 0rem;
  }

  .nav-about a {
    padding: 0.5rem 0;
  }

  .nav-about a:nth-child(2) {
    padding-top: 2rem;
  }
  .second {
    width: 100%;
  }
  .portrait {
    max-width: 100%;
  }
  #home .contact-links {
    padding: 3rem 0 0 0;
    text-align: center;
  }
  .firstletter,
  .firstname,
  .lastname {
    transform: scale(1.05);
  }

  #logo-and-name {
    animation: placementmidscreen 3s forwards;
  }
}

@media screen and (max-width: 820px) {
  .initials {
    animation: logosmallscreen 4s forwards;
  }
  .first {
    padding-left: 0;
  }

  .second {
    margin-top: 60%;
  }

  #logo-and-name {
    animation: placement 3s forwards 1.5s;
  }
  .firstletter {
    animation: none;
  }
}
@media screen and (max-width: 700px) {
  .home-content {
    width: 80%;
  }

  #home .nav-about {
    padding-left: 0;
  }
  .nav-about a {
    font-size: 1.5rem;
  }
  #home .about-link {
    width: 100%;
    padding: 0.7rem;
  }
  #home .contact-links a {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 450px) {
  #home {
    padding: 0;
    margin-top: 0;
  }

  #home .portrait {
    padding: 3rem 0 1rem 0;
    width: 100%;
  }

  #home .nav-about {
    width: 100%;
    padding: 0;
    margin-top: 1rem;
  }
  #home .nav-about a {
    font-size: 1.5rem;
  }
  #home .about-link {
    width: 100%;
  }

  #home .third {
    margin-top: 0;
  }
}

@keyframes placementmidscreen {
  0% {
    transform: translate(12%, -10%);
  }
  65% {
    transform: translate(12%, -10%);
  }
  100% {
    transform: translate(12%, -10%);
  }
}
@keyframes sizemidscreen {
  0% {
    transform: scale(1.5);
  }
  65% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes size {
  0% {
    transform: scale(1.4);
  }
  65% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes placementbigscreen {
  0% {
    transform: translate(60%, -10%);
  }
  65% {
    transform: translate(60%, -10%);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes placement {
  0% {
    margin-left: 0;
  }
  75% {
    margin-left: 0;
  }
  100% {
    margin-left: 1.4rem;
  }
}

@keyframes logobigscreen {
  0% {
    stroke-dashoffset: 400;
    opacity: 0;
    transform: scale(2) translateY(-4%) translateX(0);
  }

  20% {
    fill: rgb(255, 255, 255, 0);
  }
  75% {
    stroke-dashoffset: 0;
    opacity: 1;
    transform: scale(2) translateY(-4%) translateX(0);
  }
  100% {
    fill: #fff;
    stroke-dashoffset: 0;
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0);
  }
}

@keyframes logosmallscreen {
  0% {
    stroke-dashoffset: 400;
    opacity: 0;
    transform: scale(3.5) translateY(-4%) translateX(-1%);
  }

  50% {
    fill: rgb(255, 255, 255, 0);
  }
  75% {
    stroke-dashoffset: 0;
    opacity: 1;
    transform: scale(3.5) translateY(-4%) translateX(-1%);
  }
  100% {
    fill: #fff;
    stroke-dashoffset: 0;
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0);
  }
}

@keyframes fullnameanimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes homepagefirstgroup {
  from {
    left: -50px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes homepagesecondgroup {
  from {
    transform: translateX(500px) scale(1.5);
    opacity: 0;
    filter: blur(20px) grayscale(1);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes homepagethirdgroup {
  from {
    bottom: -300px;
    opacity: 0;
    filter: blur(20px);
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
