.navbar {
  background: linear-gradient(to right, var(--darkergreen), var(--midgreen));
}
.navbar-items {
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  margin: auto;
}
.logo {
  height: 3rem;
  padding-left: 1.5rem;
}

li {
  padding: 1.5rem;
}

.main-nav {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.main-nav li {
  height: 4rem;
  width: 8rem;
}

.about-li {
  position: relative;
}

.bi-list {
  font-size: 3rem;
  display: none;
}

.about-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  left: 6rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: var(--midgreen);
  width: 10rem;
  z-index: -1;
}

#navbar .about-nav li {
  border-bottom: 1px dotted #fff;
  width: 100%;
}

#navbar .about-nav li:last-child {
  border: none;
}
#navbar .about-nav li:hover {
  background-color: var(--todarkgreen);
}

.bi-caret-down-fill {
  color: #fff;
  position: absolute;
  top: 1.55rem;
  left: 5rem;
  transition: all 0.3s ease-in-out;
}

.hide {
  display: none;
}

.hide-dropdown {
  opacity: 0;
  top: 0;
}

.open-dropdown {
  transform: rotate(180deg);
}

@media screen and (max-width: 900px) {
  .navbar-items {
    flex-wrap: wrap;
  }

  .hamburger {
    width: 3rem;
    margin-right: 0.3rem;
  }
  .bi-list {
    display: block;
    width: 1rem;
    text-align: end;
  }
  .main-nav {
    flex-direction: column;
    background-color: var(--todarkgreen);
    width: 100%;
    text-align: center;
    height: auto;
  }
  .main-nav li {
    width: 100%;
  }
  .main-nav li:hover {
    background-color: var(--darkergreen);
  }
  .bi-caret-down-fill {
    top: 1.55rem;
    left: 55%;
  }
  #navbar .about-nav {
    position: relative;
    background-color: var(--midgreen);
    width: 100%;
    top: 0;
    left: 0;
  }
  #navbar .about-nav li {
    position: relative;
    width: 100%;
  }
  #navbar .about-nav a {
    position: relative;
  }
  .hide-dropdown {
    display: none;
    opacity: 0;
  }
}
@media screen and (max-width: 450px) {
  .bi-caret-down-fill {
    left: 57%;
  }
}
