.project-info {
  padding: 3rem;
  text-align: center;
  max-width: 45rem;
  margin: auto;
}

.project-header {
  margin-bottom: 2rem;
}

@media screen and (max-width: 700px) {
  .container {
    width: 100%;
    padding: 1rem;
  }
  .project-info {
    padding: 1rem;
  }
}
